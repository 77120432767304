import React from 'react';

const Frameworks = React.lazy(() => import('./views/frameworks/Frameworks'));
const Framework = React.lazy(() => import('./views/frameworks/Framework'));
const Groups = React.lazy(() => import('./views/groups/Groups'));
const Students = React.lazy(() => import('./views/students/Students'));
const Report = React.lazy(() => import('./views/students/report/Report'));
const Profile = React.lazy(() => import('./views/students/Profile'));
const NotFound = React.lazy(() => import('./views/pages/page404/Page404'));
const Roles = React.lazy(() => import('./views/roles/Roles'));
const Role = React.lazy(() => import('./views/roles/Role'));
const Permissions = React.lazy(() => import('./views/permissions/Permissions'));
const Users = React.lazy(() => import('./views/users/Users'));
const StudentGroups = React.lazy(() => import('./views/students/groups'));
export const privateRoutes = [
    {path: '/notFound', exact: true, name: 'NodtFound', component: NotFound},
    {path: '/', exact: true, name: 'Frameworks', component: Frameworks},
    {path: '/frameworks', exact: true, name: 'Frameworks', component: Frameworks},
    {path: '/frameworks/:id/version/:version', exact: true, name: 'Framework Details', component: Framework},
    {path: '/groups', exact: true, name: 'Groups', component: Groups},
    {path: '/groups/:id/students', exact: true, name: 'Students', component: Students},
    {path: '/groups/:groupId/students/:id/report', exact: true, name: 'StudentReport', component: Report},
    {path: '/students/:code/profile', exact: true, name: 'StudentProfile', component: Profile},
    {path: '/roles', exact: true, name: 'Roles', component: Roles},
    {path: '/roles/:id', exact: true, name: 'Permissions', component: Role},
    {path: '/permissions', exact: true, name: 'Permissions', component: Permissions},
    {path: '/students/:code/profile', exact: false, name: 'StudentProfile', component: Profile},
    {path: '/students/groups', exact: false, name: 'StudentProfile', component: StudentGroups},
    {path: '/users', exact: false, name: 'Users', component: Users}
];
export const publicRouters = [
    {path: '/', exact: true, name: 'Frameworks', component: Frameworks},
    {path: '/frameworks', exact: true, name: 'Frameworks', component: Frameworks},
    {path: '/frameworks/:id/version/:version', exact: true, name: 'Framework Details', component: Framework},
    {path: '/groups', exact: true, name: 'Groups', component: Groups},
    {path: '/groups/:id/students', exact: true, name: 'Students', component: Students},
    {path: '/students/:id/report', exact: true, name: 'StudentReport', component: Report},
    {path: '/students/:code/profile', exact: false, name: 'StudentProfile', component: Profile}
];
