import * as actionStudentGroupType from '../../constants/actionStudenGrouptType';

var initialState = {
    pending: false,
    groups: null,
    error: null,
};
var groupsOfStudentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionStudentGroupType.FETCH_GROUPS_OF_STUDENT_LOADING:
            return {
                ...state,
                pending: true,
                groups: null
            };
        case actionStudentGroupType.FETCH_GROUPS_OF_STUDENT:
            return {
                ...state,
                pending: false,
                groups: action.groups
            };
        case actionStudentGroupType.FETCH_GROUPS_OF_STUDENT_ERROR:
            return {
                ...state,
                pending: false,
                groups: null,
                error: action.error
            };
        default:
            return {...state};
    }
};
export default groupsOfStudentReducer;
