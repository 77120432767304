import * as actionProfileType from '../../constants/actionProfileType';

var initialState = {
    loadingProfile: false,
    createObjectiveLoading: false,
    updateObjectiveLoading: false,
    createInterestLoading: false,
    updateInterestLoading: false,
    createWorkLoading: false,
    updateWorkLoading: false,
    createNoteOfCoachLoading: false,
    updateNoteOfCoachLoading: false,
    createLanguageLoading: false,
    updateLanguageLoading: false,
    removeProjectLoading: false,
    profile: {
        profile: null,
        objective: null,
        interests: null,
        languages: [],
        competence: null,
        works: [],
        noteOfCoach: null,
        tutorial: null
    },
    error: null
};

var profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionProfileType.FETCH_PROFILE_LOADING:
            return {
                ...state,
                loadingProfile: true,
                profile: state.profile,
            }
        case actionProfileType.FETCH_PROFILE:
            state.profile.profile = action.profile.profile;
            state.profile.objective = action.profile.objective;
            state.profile.interests = action.profile.interest;
            state.profile.languages = action.profile.languages;
            state.profile.competence = action.profile.competence;
            state.profile.noteOfCoach = action.profile.note;
            state.profile.works = action.profile.projects;
            return {
                ...state,
                loadingProfile: false,
                user: action.user,
                profile: state.profile,
                error: null
            }
        case actionProfileType.FETCH_PROFILE_ERROR:
            state.profile.profile = null;
            state.profile.objective = null;
            state.profile.interests = null;
            state.profile.languages = [];
            state.profile.competence = null;
            state.profile.noteOfCoach = null;
            state.profile.works = [];
            return {
                ...state,
                loadingProfile: false,
                profile: state.profile,
                error: action.error
            }
        case actionProfileType.CREATE_NOTE_OF_COACH_LOADING:
            return {
                ...state,
                createNoteOfCoachLoading: true,
                profile: state.profile,
            }
        case actionProfileType.CREATE_NOTE_OF_COACH:
            state.profile.noteOfCoach = action.note;
            return {
                ...state,
                createNoteOfCoachLoading: false,
                profile: state.profile,
            }
        case actionProfileType.CREATE_NOTE_OF_COACH_ERROR:
            return {
                ...state,
                createNoteOfCoachLoading: false,
                profile: state.profile,
                error: action.error
            }

        case actionProfileType.UPDATE_NOTE_OF_COACH_LOADING:
            return {
                ...state,
                updateNoteOfCoachLoading: true,
                profile: state.profile,
            }
        case actionProfileType.UPDATE_NOTE_OF_COACH:
            state.profile.competence.map((outcome, outcomeKey) => {
                if (outcome.note && outcome.note.user_framework_id === action.note.user_framework_id) {
                    outcome.note = action.note;
                }
                return outcome;
            })
            return {
                ...state,
                updateNoteOfCoachLoading: false,
                profile: state.profile,
            }
        case actionProfileType.UPDATE_NOTE_OF_COACH_ERROR:
            return {
                ...state,
                updateNoteOfCoachLoading: false,
                profile: state.profile,
                error: action.error
            }
        case actionProfileType.CREATE_OBJECTIVE_LOADING:
            return {
                ...state,
                createObjectiveLoading: true,
                profile: state.profile,
            }
        case actionProfileType.CREATE_OBJECTIVE:
            state.profile.objective = action.objective;
            return {
                ...state,
                createObjectiveLoading: false,
                profile: state.profile,
            }
        case actionProfileType.CREATE_OBJECTIVE_ERROR:
            return {
                ...state,
                createObjectiveLoading: false,
                profile: state.profile,
                error: action.error
            }
        case actionProfileType.EDIT_OBJECTIVE_LOADING:
            return {
                ...state,
                updateObjectiveLoading: true,
                profile: state.profile,
            }
        case actionProfileType.EDIT_OBJECTIVE:
            state.profile.objective = action.objective;
            return {
                ...state,
                updateObjectiveLoading: false,
                profile: state.profile,
            }
        case actionProfileType.EDIT_OBJECTIVE_ERROR:
            return {
                ...state,
                updateObjectiveLoading: false,
                profile: state.profile,
                error: action.error
            }
        case actionProfileType.CREATE_INTERESTS_LOADING:
            return {
                ...state,
                createInterestLoading: true,
                profile: state.profile,
            }
        case actionProfileType.CREATE_INTERESTS:
            state.profile.interests = action.interests;
            return {
                ...state,
                createInterestLoading: false,
                profile: state.profile,
            }
        case actionProfileType.CREATE_INTERESTS_ERROR:
            return {
                ...state,
                createInterestLoading: false,
                profile: state.profile,
                error: action.error
            }
        case actionProfileType.EDIT_INTERESTS_LOADING:
            return {
                ...state,
                updateInterestLoading: true,
                profile: state.profile,
            }
        case actionProfileType.EDIT_INTERESTS:
            state.profile.interests = action.interests;
            return {
                ...state,
                updateInterestLoading: false,
                profile: state.profile,
            }
        case actionProfileType.EDIT_INTERESTS_ERROR:
            return {
                ...state,
                updateInterestLoading: false,
                profile: state.profile,
                error: action.error
            }
        case actionProfileType.CREATE_PROJECT_LOADING:
            return {
                ...state,
                createWorkLoading: true,
                profile: state.profile,
            }
        case actionProfileType.CREATE_PROJECT:
            state.profile.works.push(action.project);
            return {
                ...state,
                createWorkLoading: false,
                profile: state.profile,
            }
        case actionProfileType.CREATE_PROJECT_ERROR:
            return {
                ...state,
                createWorkLoading: false,
                profile: state.profile,
                error: action.error
            }
        case actionProfileType.EDIT_PROJECT_LOADING:
            return {
                ...state,
                createWorkLoading: true,
                profile: state.profile,
            }
        case actionProfileType.EDIT_PROJECT:
            let projectIndex = state.profile.works.findIndex(project => project.id === action.project.id);
            state.profile.works[projectIndex] = action.project;
            return {
                ...state,
                createWorkLoading: false,
                profile: state.profile,
            }
        case actionProfileType.EDIT_PROJECT_ERROR:
            return {
                ...state,
                createWorkLoading: false,
                profile: state.profile,
                error: action.error
            }
        case actionProfileType.CREATE_LANGUAGES_LOADING:
            return {
                ...state,
                createLanguageLoading: true,
                profile: state.profile,
            }
        case actionProfileType.CREATE_LANGUAGES:
            state.profile.languages = action.languages;
            return {
                ...state,
                createLanguageLoading: false,
                profile: state.profile,
            }
        case actionProfileType.CREATE_LANGUAGES_ERROR:
            return {
                ...state,
                createLanguageLoading: false,
                profile: state.profile,
                error: action.error
            }
        case actionProfileType.REMOVE_PROJECT_LOADING:
            return {
                ...state,
                removeProjectLoading: true,
                profile: state.profile,
            }
        case actionProfileType.REMOVE_PROJECT:
            state.profile.works = state.profile.works.filter(project => {
                return project.id !== action.projectId;
            });
            return {
                ...state,
                removeProjectLoading: false,
                profile: state.profile,
            }
        case actionProfileType.REMOVE_PROJECT_ERROR:
            return {
                ...state,
                removeProjectLoading: false,
                profile: state.profile,
                error: action.error
            }
        default:
            return {...state};
    }
}
export default profileReducer;
